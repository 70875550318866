import React from 'react';
import logo from "../../../src/assets/images/logo.jpeg";
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({ dragToAbout, dragToContact, dragToBlog, startCreating, dragToHowItWorks }) => {
  const location = useLocation(); 

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleClick = (event, section) => {
    event.preventDefault();
    scrollToTop();
    if (location.pathname === '/') { 
      window.location.hash = section;
    } else {
      
      window.location.href = `/${section}`;
    }
  };

  return (
    <div style={{ position: 'fixed', top: 0, width: '100%', backgroundColor: 'white', zIndex: 100, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      <header className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px' }}>
        <Link to="/" onClick={scrollToTop} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <div className="logo-container">
            <img className="logo" src={logo} alt="logo" style={{ height: '50px' }} />
          </div>
        </Link>
        <nav className="nav" style={{ display: 'flex' }}>
          <div className="nav-links" style={{ display: 'flex', gap: '20px' }}>
            <a href="#about" onClick={(e) => handleClick(e, '#about')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              About
            </a>
            <a href="#how-it-works" onClick={(e) => handleClick(e, '#how-it-works')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              How it works
            </a>
            <a href="#start-creating" onClick={(e) => handleClick(e, '#start-creating')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Start creating
            </a>
            <a href="#blog" onClick={(e) => handleClick(e, '#blog')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Blog
            </a>
            <a href="#contact" onClick={(e) => handleClick(e, '#contact')} className="nav-link" style={{ textDecoration: 'none', padding: '10px 15px', transition: 'color 0.3s' }}>
              Contact
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
