import React, { useState, useEffect } from 'react';
import audio from "../../../src/assets/images/Audio.png";
import pause from "../../../src/assets/images/pauseimg12.png";
import play from "../../../src/assets/images/playimg.png";

const PredefinedAudios = ({ audioOptions, onSelect, volume }) => {
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [beatLines, setBeatLines] = useState([]);
  const [playImage, setPlayImage] = useState(false);

  const generateBeatLines = () => {
    const lines = [];
    for (let i = 0; i < 10; i++) {
      const randomHeight = Math.floor(Math.random() * 20) + 5;
      const animationDelay = Math.random() * 1;
      lines.push({ height: randomHeight, delay: animationDelay });
    }
    setBeatLines(lines);
  };

  const handleRadioChange = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    const selectedOption = audioOptions[selectedIndex];
    onSelect(selectedOption);
    setSelectedAudio(selectedOption);
    setIsPlaying(false);
    setPlayImage(true);
  };

  useEffect(() => {
    if (selectedAudio) {
      const audioElement = new Audio(selectedAudio.src);
      audioElement.volume = volume / 100;
      setAudioPlayer(audioElement);
      setIsPlaying(false);

      return () => {
        audioElement.pause();
        audioElement.currentTime = 0;
        setAudioPlayer(null);
        setBeatLines([]);
      };
    }
  }, [selectedAudio, volume]);

  useEffect(() => {
    if (audioPlayer && isPlaying) {
      audioPlayer.play().then(() => {
        generateBeatLines();
      }).catch(error => {
        console.error('Failed to play audio:', error);
      });
    } else if (audioPlayer && !isPlaying) {
      audioPlayer.pause();
    }
  }, [audioPlayer, isPlaying]);

  const playAudio = () => {
    if (audioPlayer) {
      setIsPlaying(true);
    }
  };

  const stopAudio = () => {
    if (audioPlayer) {
      setIsPlaying(false);
      audioPlayer.currentTime = 0;
      setBeatLines([]);
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "30px", position: "relative" }}>
      <ul className='audio-type' style={{ listStyleType: "none" }}>
        {audioOptions.map((option, index) => (
          <li key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <input
              type="radio"
              name="audioSelection"
              value={index}
              onChange={handleRadioChange}
              style={{ width: "30px", height: "30px", border: "3px solid #f492ed", borderRadius: "100%", backgroundColor: "white" }}
            />
            <img
            className='play-pause'
              style={{
                width: "80px",
                height: "80px",
                opacity: 1,
                cursor: 'pointer'
              }}
              src={selectedAudio?.title === option?.title ? ((selectedAudio?.title === option?.title) && (!isPlaying)) ? play : (isPlaying ? pause : play) : audio}
              alt="Audio icon"
              onClick={() => {
                if (selectedAudio?.title === option?.title) {
                  if (isPlaying) {
                    stopAudio();
                  } else {
                    playAudio();
                  }
                } else {
                  setSelectedAudio(option);
                  setIsPlaying(false);
                }
              }}
            />
            <p className="font-35 font-18 pink-color" style={{ fontWeight: "bold" }}>{option.title}</p>
            <p className="binaural-text font-18">(Binaural beats)</p>

            {selectedAudio?.title === option?.title && isPlaying && (
              <div className="beats-container">
                {beatLines.map((line, idx) => (
                  <div
                    key={idx}
                    className="beat-line"
                    style={{
                      height: `${line.height}px`,
                      animationDelay: `${line.delay}s`
                    }}
                  />
                ))}
              </div>
            )}

          </li>
        ))}
      </ul>

    </div>
  );
};

export default PredefinedAudios;
