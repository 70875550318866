import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../config';

const Record = () => {
  const [priceData, setPriceData] = useState({ price: 0, currency: '' });

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BASE_URL}/price`);

        if (response.data.success && response.data.data.length > 0) {
          const priceInfo = response.data.data[0]; 
          setPriceData({ price: priceInfo.price, currency: priceInfo.currency });
        }
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };

    fetchPriceData();
  }, []);

  const oldPrice = (priceData.price * 1.3).toFixed(2);

  return (
    <section className="main-content">
      <h1 className="main-title">
        Record Your Own Affirmations
      </h1>
      <div className="content-box" style={{ position: 'relative' }}>
        <p className="content-text font-18 font-22">
          Welcome to our unique platform where you can <br />
          <strong>record your personal affirmations</strong> and have them <br />
          seamlessly <strong>integrated and looped</strong> into a{' '}
          <strong>
            30-minute <br /> manifestation soundtrack
          </strong>{' '}
          of your choosing. Harness the <br /> power of your own voice combined with our specially{' '}
          <br /> designed background music to enhance your <br /> manifestation practice.
        </p>

        <div
          style={{
            position: 'absolute',
            bottom: '-150px', 
            left: '-100px', 
            width: '190px', 
            padding: '5px',
            height: '190px',
            backgroundColor: '#f492ed',
            borderRadius: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            textAlign: 'center',
            fontSize: '20px',
            marginBottom: '15px',
            fontWeight: 'bold',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div className="price-text">Pricing</div>
          <div className="old-price">
            {oldPrice} $
          </div>
          <div className="new-price">
            {priceData.price.toFixed(2)} $
          </div>
        </div>
      </div>
    </section>
  );
};

export default Record;
