import React from 'react'
const OurTool = ({blog}) => {
  return (
    <>
      <section>
        <div ref={blog} className="tool-benefits">
          <h2 className="title" style={{ textShadow: "6px 6px #f492ed" }}>
            Why you should use our tool
          </h2>
          <div className="benefits-container">
            <div className="benefit">
              <h4 className="benefit-title">Personalized Experience</h4>
              <p className="gray-color" style={{ fontSize: "22px" }}>
                Your affirmations in <br /> your <strong>own voice</strong> are{" "}
                <strong>
                  more <br /> powerful and meaningful
                </strong>{" "}
                <br /> , creating a deeply personal <br /> manifestation tool.
              </p>
            </div>
            <div className="benefit">
              <h4 className="benefit-title">High-Quality Soundtrack</h4>
              <p className="gray-color" style={{ fontSize: "22px" }}>
                Our{" "}
                <strong>
                  professional-grade <br /> manifestation soundtrack
                </strong>{" "}
                <br /> is crafted to support <br /> relaxation, focus, and{" "}
                <br /> positive energy.
              </p>
            </div>
            <div className="benefit">
              <h4 className="benefit-title">Instant Access</h4>
              <p className="gray-color" style={{ fontSize: "22px" }}>
                Get your custom audio <br /> immediately after purchase <br />{" "}
                with a <strong>one time cost</strong>, and <br /> start
                manifesting your <br />
                dreams right away.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurTool