import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APP_BASE_URL, REACT_APP_BASE_URL } from '../../config';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [viewingDetail, setViewingDetail] = useState(false);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${REACT_APP_BASE_URL}/blogs`);
                if (response.data.success) {
                    setBlogs(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const handleBlogClick = (blog) => {
        setSelectedBlog(blog);
        setViewingDetail(true);
    };

    const handleBackToList = () => {
        setViewingDetail(false);
        setSelectedBlog(null);
    };

    const defaultImage = 'https://via.placeholder.com/800x600'; // Default image URL

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', marginTop: '50px' }}>
            <h1 style={{ fontSize: '2.5rem', marginBottom: '20px' }} onClick={handleBackToList}>Blogs</h1>

            {viewingDetail ? (
                // Detailed Blog View
                <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                  
                    {selectedBlog && (
                        <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px' }}>
                            <img
                                src={selectedBlog.images.length > 0 ? `${APP_BASE_URL}/${selectedBlog.images[0]}` : defaultImage}
                                alt={selectedBlog.title}
                                style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                            />
                            <h2 style={{ fontSize: '2rem', margin: '20px 0' }}>{selectedBlog.title}</h2>
                            <p style={{ fontSize: '1rem', lineHeight: '1.5' }}>{selectedBlog.content}</p>
                        </div>
                    )}
                      <button
                        onClick={handleBackToList}
                        style={{
                            display: 'block',
                            margin: '30px auto 20px',
                            padding: '10px 20px',
                            fontSize: '1rem',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Back to List
                    </button>
                </div>
            ) : (
                <>
                    {/* Horizontal Scrolling Container */}
                    <ScrollMenu
                        style={{ overflowX: 'hidden', marginBottom: '20px', paddingBottom: '10px', borderBottom: '2px solid #ddd' }}
                        wrapperStyle={{ display: 'flex' }}
                    >
                        {blogs.map((blog) => (
                            <div
                                key={blog._id}
                                style={{
                                    display: 'inline-block',
                                    marginRight: '15px',
                                    cursor: 'pointer',
                                    border: '2px solid #ddd',
                                    borderRadius: '10px',
                                    overflow: 'hidden',
                                    width: '400px', // Increased width
                                    height: '300px', // Increased height
                                    position: 'relative'
                                }}
                                onClick={() => handleBlogClick(blog)}
                            >
                                <img
                                    src={blog.images.length > 0 ? `${APP_BASE_URL}/${blog.images[0]}` : defaultImage}
                                    alt={blog.title}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </div>
                        ))}
                    </ScrollMenu>

                    {/* Vertical Scrolling Blogs List */}
                    <div style={{ marginTop: '20px' }}>
                        {blogs.map((blog) => (
                            <div
                                key={blog._id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                    borderBottom: '1px solid #ddd',
                                    paddingBottom: '10px',
                                }}
                            >
                                <img
                                    src={blog.images.length > 0 ? `${APP_BASE_URL}/${blog.images[0]}` : defaultImage}
                                    alt={blog.title}
                                    style={{
                                        width: '150px', // Larger image width
                                        height: '100px', // Larger image height
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        marginRight: '20px',
                                    }}
                                />
                                <div style={{ flex: '1' }}>
                                    <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>{blog.title}</h3>
                                    <p style={{ fontSize: '1rem', color: '#555', margin: 0 }}>
                                        {blog.content.substring(0, 200)}...<br />
                                        <span style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => handleBlogClick(blog)}>
                                            Read more
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogPage;
