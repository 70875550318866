// Utility function to convert a Blob to a File
export const blobToFile = (blob, filename) => {
  return new File([blob], filename, { type: blob.type });
};

// Function to create a new audio file with a specified duration
export const createLongAudio = async (audioFile, targetDurationSeconds) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  console.log('audiouuuuukkkkkkk,,,,,,,', audioFile)
  const arrayBuffer = await audioFile.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  const durationSeconds = audioBuffer.duration;
  const repeatCount = Math.ceil(targetDurationSeconds / durationSeconds);
  const newBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    audioBuffer.length * repeatCount,
    audioBuffer.sampleRate
  );

  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = newBuffer.getChannelData(channel);
    for (let i = 0; i < repeatCount; i++) {
      channelData.set(audioBuffer.getChannelData(channel), i * audioBuffer.length);
    }
  }

  const finalLength = Math.min(newBuffer.length, audioBuffer.sampleRate * targetDurationSeconds);
  const trimmedBuffer = audioContext.createBuffer(
    newBuffer.numberOfChannels,
    finalLength,
    newBuffer.sampleRate
  );

  for (let channel = 0; channel < newBuffer.numberOfChannels; channel++) {
    trimmedBuffer.copyToChannel(newBuffer.getChannelData(channel).subarray(0, finalLength), channel);
  }

  const offlineContext = new OfflineAudioContext(
    trimmedBuffer.numberOfChannels,
    trimmedBuffer.length,
    trimmedBuffer.sampleRate
  );
  const source = offlineContext.createBufferSource();
  source.buffer = trimmedBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  const wavBlob = bufferToWave(renderedBuffer);
  return blobToFile(wavBlob, 'long-audio.mp3');
};
export const trimAudio = async (audioFile, targetDurationSeconds) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  let arrayBuffer;

  // Check if audioFile is a File or Blob
  if (audioFile instanceof Blob || audioFile instanceof File) {
    arrayBuffer = await audioFile.arrayBuffer();
  } else if (typeof audioFile === 'object' && audioFile.src) {
    // If it's an object with a src property (assumed to be your { src } object)
    const response = await fetch(audioFile.src);
    if (!response.ok) {
      throw new Error(`Failed to fetch audio file: ${response.status} ${response.statusText}`);
    }
    arrayBuffer = await response.arrayBuffer();
  } else {
    throw new Error('Invalid audio file provided.');
  }

  // Decode audio data
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Calculate trimming parameters and create new buffer
  const durationSeconds = audioBuffer.duration;
  const repeatCount = Math.ceil(targetDurationSeconds / durationSeconds);
  const newBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    audioBuffer.length * repeatCount,
    audioBuffer.sampleRate
  );

  // Copy original audio data into the new buffer
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = newBuffer.getChannelData(channel);
    for (let i = 0; i < repeatCount; i++) {
      channelData.set(audioBuffer.getChannelData(channel), i * audioBuffer.length);
    }
  }

  // Create a trimmed buffer based on the target duration
  const finalLength = Math.min(newBuffer.length, audioBuffer.sampleRate * targetDurationSeconds);
  const trimmedBuffer = audioContext.createBuffer(
    newBuffer.numberOfChannels,
    finalLength,
    newBuffer.sampleRate
  );

  // Copy data from newBuffer to trimmedBuffer
  for (let channel = 0; channel < newBuffer.numberOfChannels; channel++) {
    trimmedBuffer.copyToChannel(newBuffer.getChannelData(channel).subarray(0, finalLength), channel);
  }

  // Render trimmed audio asynchronously
  const offlineContext = new OfflineAudioContext(
    trimmedBuffer.numberOfChannels,
    trimmedBuffer.length,
    trimmedBuffer.sampleRate
  );
  const source = offlineContext.createBufferSource();
  source.buffer = trimmedBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  const wavBlob = bufferToWave(renderedBuffer);
  return blobToFile(wavBlob, 'trimmed-audio.mp3');
};




// Helper function to convert AudioBuffer to WAV blob
const bufferToWave = (abuffer) => {
  const numOfChan = abuffer.numberOfChannels;
  const length = abuffer.length * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  const channels = [];
  let i;
  let sample;
  let offset = 0;
  let pos = 0;

  // write WAV header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (i = 0; i < abuffer.numberOfChannels; i++) {
    channels.push(abuffer.getChannelData(i));
  }

  while (pos < length) {
    for (i = 0; i < numOfChan; i++) {
      // interleave channels
      sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  return new Blob([buffer], { type: 'audio/wav' });

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
};
