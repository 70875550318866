import React from 'react';

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <div className="terms-heading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ff69b4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-file-text"
        >
          <path d="M4 1v22h16V7.5L14.5 1H4z"></path>
          <polyline points="14 1 14 7 20 7"></polyline>
          <line x1="16" y1="5" x2="8" y2="5"></line>
          <polyline points="10 18 6 18 6 14"></polyline>
          <line x1="9" y1="15" x2="9" y2="21"></line>
        </svg>
        <h1>Terms and Conditions of Yourownaffirmations.com</h1>
      </div>

      {/* <p className="modified-date">Last modified on [Date]</p> */}

      <section className="section-content">
        <h2>Introduction</h2>
        <p>
          Welcome to Yourownaffirmations.com. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree with any part of these terms and conditions, you must not use our website.
        </p>
      </section>

      <section className="section-content">
        <h2>Services</h2>
        <p>
          Yourownaffirmations.com offers a service where users can record their own affirmations, which are then integrated into a manifestation soundtrack for download after payment.
        </p>
      </section>

      <section className="section-content">
        <h2>User Responsibilities</h2>
        <ul>
          <li>Accuracy of Information: Users must provide accurate and up-to-date information during the registration and payment processes.</li>
          <li>Use of Service: Users agree to use our service for lawful purposes only and not to use it in any way that could damage our reputation or the functionality of our website.</li>
          <li>Account Security: Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer.</li>
        </ul>
      </section>

      <section className="section-content">
        <h2>Payments and Refunds</h2>
        <ul>
          <li>Payments: All payments must be made through our secure payment gateway. By making a payment, you agree to the price and terms stated at the time of purchase.</li>
          <li>Refunds: Due to the personalized nature of our service, we do not offer refunds once the recording process has been completed and the audio file has been delivered. You can pre-listen your audio-file (first 10 seconds only), so it is your responsibility that you listen to it before purchasing.</li>
          <li>No money back guarantee: If users don’t succeed in manifesting after using an audio file generated by our tool, we can’t give money back. Manifesting is a technique that has to be mastered first and our service is just a tool to support manifestation, most of the work and the most important part is done by the user itself.</li>
        </ul>
      </section>

      <section className="section-content">
        <h2>Intellectual Property</h2>
        <p>
          Ownership: All content on Yourownaffirmations.com, including text, graphics, logos, and audio, is the property of Yourownaffirmations.com and is protected by international copyright laws.
        </p>
        <p>
          User License: Users are granted a limited, non-exclusive, and non-transferable license to access and use the website for personal, non-commercial purposes.
        </p>
      </section>

      <section className="section-content">
        <h2>Limitation of Liability and Responsibility</h2>
        <ul>
          <li>Yourownaffirmations.com is not liable for any indirect, incidental, or consequential damages arising from the use of our services or the inability to use our services. Our total liability to you for any claim arising from our services is limited to the amount you paid us for the service.</li>
          <li>Yourownaffirmations.com can’t be held responsible for not succeeding in manifesting with the audio file. We provide just a tool to work with, not a guarantee of success.</li>
        </ul>
      </section>

      <section className="section-content">
        <h2>Privacy Policy</h2>
        <p>Your use of the website is also governed by our <a href="/privacy-policy">Privacy Policy</a>.</p>
      </section>

      <section className="section-content">
        <h2>Changes to the Terms and Conditions</h2>
        <p>
          We may update these terms and conditions from time to time. When we do, we will post the updated terms on this page and update the modification date below. Your continued use of the website after any changes signifies your acceptance of the new terms.
        </p>
        {/* <p className="modified-date">Last modified on [Date].</p> */}
      </section>

      <section className="section-content">
        <h2>Governing Law</h2>
        <p>
          These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of [Your Country].
        </p>
      </section>

      <section className="section-content">
        <h2>Contact Information</h2>
        <p>
          If you have any questions about these terms and conditions, please contact us at:
        </p>
        <p>
          Yourownaffirmations.com<br />
          Email: hello@yourownaffirmations.com
        </p>
      </section>
    </div>
  );
};

export default TermsConditions;
