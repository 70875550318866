import React from 'react'
import icon7 from "../../../src/assets/images/icon-7.png";
import icon5 from "../../../src/assets/images/icon-5.png";
import icon4 from "../../../src/assets/images/icon-4.png";
import icon2 from "../../../src/assets/images/icon-2.png";
import icon6 from "../../../src/assets/images/icon-6.png";
const PersonalAffirmation = ({ howItWorks, clickHere }) => {
  return (
    <>
      <section>
        <div ref={howItWorks}
        className='personal-affirmation'
          style={{
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
            color: "red",
            textAlign: "center",
          }}
        >
          <h3
          className='personal-affirmation-heading'
            style={{
              color: "white",
              marginTop: "40px",
              marginBottom: "2px",
              padding: "10px 20px 10px 20px",
              borderRadius: "40px",
              background:
                "linear-gradient(to right, #b5eaef, #ede38f, #f492ed)",
              textShadow: "4px 4px #f492ed",
            }}
          >
            Create your personal affirmation soundtrack <br />
            <a style={{ color: "white" }} href="#!" onClick={clickHere}>
              HERE
            </a>
          </h3>
        </div>
        <div className="container">
          <div
            style={{
              fontSize: "35px",
              display: "flex",
              justifyContent: "center",
              color: "red",
            }}
          >
            <h1
            className='font-40'
              style={{
                color: "#f492ed",
                marginTop: "0px",
                marginBottom: "2px",

              }}
            >
              How it Works
            </h1>
          </div>
          <div className="steps">
            <div className="step">
              <div className="icon">
                <img className="icon-size" src={icon7} alt="Step 1" />
              </div>
              <h2
                className="color font-25"
                style={{ marginTop: "5px", marginBottom: "0px" }}
              >
                Step 1
              </h2>
              <p className="description font-22">
                <strong className="color">Record Your Affirmations</strong>
                <br />
                Use our easy-to-use online recorder to capture your affirmations
                in your own voice{" "}
                <strong>or upload a pre-recorded audio-file.</strong>
                Not happy? You can start over as much as you like by clicking the ‘re-record or re-upload audio’-button.
              </p>
            </div>
            <div className="step">
              <div className="icon">
                <img className="icon-size" src={icon5} alt="Step 2" />
              </div>
              <h2
                className="color font-25"
                style={{ marginTop: "5px", marginBottom: "0px" }}
              >
                Step 2
              </h2>
              <p className="description font-22">
                <strong className="color">Automatic Integration</strong>
                <br />
                Once recorded/uploaded, you can choose a soundtrack. Choose ‘merge audio’ our system will automatically loop your affirmation over the 30 minute manifestation soundtrack designed to amplify your positive intentions.
              </p>
            </div>
            <div className="step">
              <div className="icon">
                <img className="icon-size" src={icon4} alt="Step 3" />
              </div>
              <h2
                className="color font-25"
                style={{ marginTop: "5px", marginBottom: "0px" }}
              >
                Step 3
              </h2>
              <p className="description font-22">
                <strong className="color">Pre-listen</strong>
                <br />
                A 10 second pre-listen audio is generated. You can check if you like how it sounds and adjust the volume of both your recording/upload and the soundtrack. Happy with the result? Click the ‘create full audio affirmation’-button
              </p>
            </div>
          </div>
          <div className="steps">
            <div className="step">
              <div className="icon">
                <img className="icon-size" src={icon2} alt="Step 4" />
              </div>
              <h2
                className="color font-25"
                style={{ marginTop: "5px", marginBottom: "0px" }}
              >
                Step 4
              </h2>
              <p className="description font-22">
                <strong className="color">Secure Payment</strong>
                <br />
                After the merging is done (this can take a few minutes) and before downloading, you can pay for the recording via the secure payment process.
              </p>
            </div>
            <div className="step">
              <div className="icon">
                <img className="icon-size" src={icon6} alt="Step 5" />
              </div>
              <h2
                className="color font-25"
                style={{ marginTop: "5px", marginBottom: "0px" }}
              >
                Step 5
              </h2>
              <p className="description font-22">
                <strong className="color">Download Custom Audio</strong>
                <br />
                After successful payment, you'll be able to download your
                personalized audio track and start using it immediately.
                Manifesting time baby!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PersonalAffirmation