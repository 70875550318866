import React, { useState, useEffect, useRef } from 'react';

const CustomLoader = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

const AudioPlayer = ({ recordedAudio, audioFile, volume }) => {
  const [isLoadingRecordedAudio, setIsLoadingRecordedAudio] = useState(false);
  const [isLoadingUploadedAudio, setIsLoadingUploadedAudio] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (recordedAudio) {
      setIsLoadingRecordedAudio(true);
      // Simulate loading delay for recorded audio
      setTimeout(() => {
        setIsLoadingRecordedAudio(false);
      }, 1000);
    }
    if (audioFile) {
      setIsLoadingUploadedAudio(true);
      // Simulate loading delay for uploaded audio
      setTimeout(() => {
        setIsLoadingUploadedAudio(false);
      }, 1000);
    }
  }, [recordedAudio, audioFile]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      {isLoadingRecordedAudio || isLoadingUploadedAudio ? (
        <CustomLoader size={50} color="#f492ed" />
      ) : (
        <>
          {recordedAudio && (
            <div
              style={{
                width: '45%',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <h2 style={{ marginBottom: '10px', color: '#f492ed' }}>Recorded Audio:</h2>
              <audio ref={audioRef} controls style={{ width: '100%', outline: 'none', borderRadius: '5px' }}>
                <source src={URL.createObjectURL(recordedAudio)} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
          {audioFile && (
            <div
              style={{
                width: '45%',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <h2 style={{ marginBottom: '10px', color: '#f492ed' }}>Uploaded Audio:</h2>
              <audio ref={audioRef} controls className="audio-without-volume" style={{ width: '100%', outline: 'none', borderRadius: '5px' }}>
                <source src={URL.createObjectURL(audioFile)} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AudioPlayer;
