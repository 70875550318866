import React from 'react';
import youtube from "../../../src/assets/images/youtube.png";
import instagram from "../../../src/assets/images/instagram.png";
import facebook from "../../../src/assets/images/facebook.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section>
      <footer className="footer">
        <div className="footer-content">
          <p className="footer-address">
            The Dream Miners bv <br />
            Houtheide 32, 3150 Haacht, BE <br />
            VAT: BE
          </p>
          <p className="footer-info">
        © 2024 yourownaffirmations.com &nbsp; &nbsp;
        <Link to="/terms-and-conditions" style={{ color: 'white', textDecoration: 'none' }}>Terms and conditions</Link>
        &nbsp; | &nbsp;
        <Link to="/privacy-policy" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>Privacy Policy</Link>
      </p>
        </div>

        <div className="footer-social">
          <p>Follow us</p>
          <div className="social-icons-container">
            <a href="https://www.youtube.com/channel/UCbJnE5KujiLoq940y_-WHFg" target="_blank" rel="noopener noreferrer">
              <img className="social-icons" src={youtube} alt="youtube" />
            </a>
            <a href="https://www.instagram.com/yourownaffirmations/" target="_blank" rel="noopener noreferrer">
              <img className="social-icons" src={instagram} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559554287269" target="_blank" rel="noopener noreferrer">
          <img className="social-icons" src={facebook} alt="facebook" />
        </a>
          </div>

        </div>
      </footer>
    </section>
  );
}

export default Footer;
