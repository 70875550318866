import React from 'react'
import icon9 from "../../../src/assets/images/icon-9.png";
import icon8 from "../../../src/assets/images/icon-8.png";
const Contact = ({ contact }) => {
  return (
    <>
      <section>
        <div ref={contact}
          className="padding-container"
          style={{
            fontSize: "30px",
            display: "flex",
            justifyContent: "start",
            color: "red",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              color: "#f492ed",
              marginTop: "0px",
              marginBottom: "5px",
            }}
          >
            Contact
          </h2>
        </div>
        <div className="steps">
          <a href="mailto:hello@yourownaffirmations.com" className="step color">
            <div className="icon">
              <img className="contact-icon" src={icon9} alt="Step 4" />
            </div>
            <h2>Send us an email</h2>
          </a>

          <div className="step">
            <div className="icon">
              <img className="contact-icon" src={icon8} alt="Step 5" />
            </div>
            <h2 className="color">Chat with us</h2>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact