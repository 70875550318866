import React, { useState, useRef } from 'react';
import { CustomLoader } from '../All Components/customLoader';
import { trimAudio } from '../../commonFunctions/commonFunctions';
import pauseImage from "../../../src/assets/images/pauseimg12.png";
import playImage from "../../../src/assets/images/playimg.png";
import ReactAudioPlayer from 'react-h5-audio-player';
import axios from 'axios'
import 'react-h5-audio-player/lib/styles.css';
import { REACT_APP_BASE_URL } from '../../../src/config'

function MergePlayAudio({ audioFile1, audioFile2, volumePercentage1, volumePercentage2, setShowMergeButton }) {
  const audioPlayer1 = useRef(null);
  const audioPlayer2 = useRef(null);
  const [mergedAudioUrl1, setMergedAudioUrl1] = useState(null);
  const [mergedAudioUrl2, setMergedAudioUrl2] = useState(null);
  const [mergedAudioUrl, setMergedAudioUrl] = useState(null);
  const [isMerging, setIsMerging] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume1, setVolume1] = useState(volumePercentage1);
  const [volume2, setVolume2] = useState(volumePercentage2);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    // Reset error message when user types
    if (couponError) {
      setCouponError('');
    }
  };
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);

    if (audioPlayer1.current) {
      if (!isPlaying) {
        audioPlayer1.current.play();
      } else {
        audioPlayer1.current.pause();
      }
    }
    if (audioPlayer2.current) {
      if (!isPlaying) {
        audioPlayer2.current.play();
      } else {
        audioPlayer2.current.pause();
      }
    }
  };

  const mergeAudio = async () => {
    if (!audioFile1 || !audioFile2) {
      alert('Please select both audio files.');
      return;
    }

    setIsMerging(true);

    try {

      const tempAudioElement = new Audio();
      tempAudioElement.src = typeof audioFile1 === 'string' ? audioFile1 : audioFile1.src;

      await new Promise((resolve, reject) => {
        tempAudioElement.onloadedmetadata = resolve;
        tempAudioElement.onerror = reject;
      });



      const trimmedAudio1 = await trimAudio(audioFile2, 10);
      const trimmedAudio2 = await trimAudio(audioFile1, 10);
      console.log('trimmedAudio1,,,,,', trimmedAudio1, trimmedAudio2)
      setMergedAudioUrl1(URL.createObjectURL(trimmedAudio1));
      setMergedAudioUrl2(URL.createObjectURL(trimmedAudio2));

    } catch (error) {
      console.error('Error merging audio:', error);
    } finally {
      setIsMerging(false);
      setShowMergeButton(true);
    }
  };
  const handleVolumeChange1 = (e) => {
    setVolume1(e.target.value);
    if (audioPlayer1.current) {
      audioPlayer1.current.volume = e.target.value / 100;
    }
  };
  const handleVolumeChange2 = (e) => {
    setVolume2(e.target.value);
    if (audioPlayer2.current) {
      audioPlayer2.current.volume = e.target.value / 100;
    }
  };

  const handleSeek = (player, time) => {
    if (player === 'player1' && audioPlayer2.current) {
      audioPlayer2.current.currentTime = time;
    } else if (player === 'player2' && audioPlayer1.current) {
      audioPlayer1.current.currentTime = time;
    }
  };
  const handlepayment = async () => {
    if (couponCode) {
      try {
        const couponResponse = await axios.post(`${REACT_APP_BASE_URL}/coupon/check-coupon`, { couponCode });

        if (!couponResponse.data.success) {
          setCouponError('Invalid coupon code.');
          setIsProcessing(false);
          return;
        }
      } catch (error) {
        console.error("Error checking coupon code:", error);
        setCouponError('Error validating coupon code.');
        setIsProcessing(false);
        return;
      }
    }

    setIsProcessing(true);

    try {
      const formData = new FormData();
      formData.append('audioFile1', audioFile1.title);
      formData.append('audioFile2', audioFile2);
      formData.append('volume1', volume1);
      formData.append('volume2', volume2);
      formData.append('couponCode', couponCode);
      formData.append('email', email); // Include the email in the form data

      const sessionResponse = await axios.post(`${REACT_APP_BASE_URL}/session`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (sessionResponse.data?.session?._id) {
        localStorage.setItem('sessionId', sessionResponse.data.session._id);

        const paymentResponse = await axios.post(`${REACT_APP_BASE_URL}/paypal-payment`, {
          sessionId: sessionResponse.data.session._id,
          couponCode, 
          email 
        });

        window.location.href = paymentResponse.data;
      } else {
        throw new Error('Session creation failed. No session ID returned.');
      }
    } catch (error) {
      console.error("Error during payment processing:", error.response?.data || error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {!mergedAudioUrl && !mergedAudioUrl1 && !mergedAudioUrl2 && (
        <button
          style={{
            background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
            color: 'black',
            padding: '10px 20px',
            borderRadius: '32px',
            cursor: 'pointer',
            marginBottom: '30px',
            width: '250px',
            fontWeight: 'bold',
            fontSize: '20px',
            opacity: isMerging ? 0.5 : 1,
            pointerEvents: isMerging ? 'none' : 'auto',
            position: 'relative',
          }}
          onClick={mergeAudio}
          disabled={isMerging}
        >
          {isMerging ? (
            <>
              <span style={{ marginRight: '10px' }}>Merging...</span>
              <CustomLoader size={20} color="black" />
            </>
          ) : (
            'Merge Audio'
          )}
        </button>
      )}
      {mergedAudioUrl1 && mergedAudioUrl2 && (
        <div style={{
          marginLeft: '30px',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <p
            style={{
              marginTop: '20px',
              borderRadius: '20px',
              padding: '10px 20px', // Adjusted padding
              textAlign: 'center',
              maxWidth: '80%',
              color: 'black',
              backgroundColor: '#f9f9f9'
            }}
          >
            <strong>This is only a preview</strong> of the audio file. Here you can adjust the audio of both your own audio and the manifestation soundtrack. Happy with the result? Click the ‘Create full affirmation audio’-button to generate the full 30 minute merged audio file. (This can take a few minutes) You have to pay before you can download.
          </p>
        </div>
      )}
      {mergedAudioUrl1 && mergedAudioUrl2 && (
        <div className="audio-player-wrapper">
          <audio
            ref={audioPlayer1}
            src={mergedAudioUrl1}
            controls
            className="audio-without-volumeplay audio-without-volume"
            style={{ width: '80%', outline: 'none', borderRadius: '5px' }}
            onTimeUpdate={() => handleSeek('player1', audioPlayer1.current.currentTime)}
          >
            Your browser does not support the audio element.
          </audio>
          <audio
            ref={audioPlayer2}
            src={mergedAudioUrl2}
            controls
            className="audio-without-volumeplay audio-without-volume"
            style={{ width: '100%', outline: 'none', borderRadius: '5px', display: 'none' }}
          // onTimeUpdate={() => handleSeek('player2', audioPlayer2.current.currentTime)}
          >
            Your browser does not support the audio element.
          </audio>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
              <button onClick={handlePlayPause} style={{
                padding: 0,
                border: 'none',
                cursor: 'pointer',
                outline: 'none',
                // position: 'relative',
              }}>
                {isPlaying ? (
                  <img src={pauseImage} alt="Pause" style={{ width: '50px', height: '50px' }} />
                ) : (
                  <img src={playImage} alt="Play" style={{ width: '50px', height: '50px' }} />
                )}
              </button>


            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px', marginTop: '10px' }}>
              <div className="volume-control" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>Adjust Volume</span>
                  <span style={{ fontWeight: 'normal' }}> (soundtrack):</span>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={volume1}
                    onChange={handleVolumeChange1}
                    style={{ marginRight: '10px', marginLeft: '20px' }}
                  />
                  <span className="volume-percentage" style={{ minWidth: '50px' }}>{volume1}%</span>
                </label>
              </div>
              <div className="volume-control" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>Adjust Volume</span>
                  <span style={{ fontWeight: 'normal' }}> (Uploaded/Recorded):</span>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={volume2}
                    onChange={handleVolumeChange2}
                    style={{ marginRight: '10px', marginLeft: '20px' }}
                  />
                  <span className="volume-percentage" style={{ minWidth: '50px' }}>{volume2}%</span>
                </label>
              </div>
            </div>
          </div>

        </div>
      )}

      {mergedAudioUrl1 && mergedAudioUrl2 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            maxWidth: '400px',
            margin: '0 auto',
            border: '1px solid #e0e0e0'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px', marginRight: '10px' }}>
                Coupon:<span style={{ fontWeight: 'normal' }}></span>
              </label>
              <input
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={handleCouponChange}
                style={{
                  padding: '10px 20px',
                  borderRadius: '20px',
                  width: '250px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  outline: 'none',
                  transition: 'border-color 0.3s ease',
                }}
                onFocus={(e) => (e.target.style.borderColor = '#f492ed')}
                onBlur={(e) => (e.target.style.borderColor = '#ccc')}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px', marginRight: '10px' }}>
                Email: 
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                style={{
                  padding: '10px 20px',
                  borderRadius: '20px',
                  width: '250px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  outline: 'none',
                  transition: 'border-color 0.3s ease',
                }}
                onFocus={(e) => (e.target.style.borderColor = '#f492ed')}
                onBlur={(e) => (e.target.style.borderColor = '#ccc')}
              />
            </div>
          </div>

          {couponError && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{couponError}</div>
          )}
          {mergedAudioUrl && (
            <div style={{ marginTop: '20px', marginBottom: '30px' }}>
              <ReactAudioPlayer src={mergedAudioUrl} autoPlay={false} controls />
            </div>
          )}
          <button
            style={{
              background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
              color: 'black',
              padding: '10px 10px',
              borderRadius: '32px',
              cursor: isProcessing ? 'not-allowed' : 'pointer',
              width: '320px',
              fontWeight: 'bold',
              fontSize: '20px',
              opacity: isProcessing ? 0.5 : 1,
              pointerEvents: isProcessing ? 'none' : 'auto',
              marginTop: '10px',
              marginBottom: '20px',

            }}
            onClick={handlepayment}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <CustomLoader size={30} color="black" />
            ) : (
              'Buy now'
            )}
          </button>
        </div>
      )}



    </div>
  );
}

export default MergePlayAudio;
