import React, { useRef, useState } from 'react';
import PredefinedAudios from './PredefinedAudios';
import MergePlayAudio from '../MergePlayAudio/MergePlayAudio';
import AudioPlayer from './AudioPlayer.js';
import pause from "../../../src/assets/images/pauseimg12.png";
import play from "../../../src/assets/images/playimg.png";
import stop from "../../../src/assets/images/stopimg.png";
import { audioOptions } from '../utils.js';

const Application = ({
  bottomRef,
  audioFile,
  setAudioFile,
  recordedAudio,
  setRecordedAudio,
  isRecording,
  setIsRecording,
}) => {
  const [volumePercentage1, setVolumePercentage1] = useState(100);
  const [volumePercentage2, setVolumePercentage2] = useState(100);
  const [convertFile, setConvertFile] = useState(null);
  const [showMergeButton, setShowMergeButton] = useState(false);

  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [isRecordingPulse, setIsRecordingPulse] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showRecord, setShowRecord] = useState(true);
  const [showUpload, setShowUpload] = useState(true);
  const [selectedPredefinedAudio, setSelectedPredefinedAudio] = useState(null);
  const handleAudioFileChange = (event) => {
    setAudioFile(event.target.files[0]);
    setRecordedAudio(null);
  };

  const handleRecording = () => {
    if (isRecording) {
      if (isPaused) {
        mediaRecorder.current.resume();
        setIsPaused(false);
      } else {
        mediaRecorder.current.pause();
        setIsPaused(true);
      }
    } else {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => chunks.current.push(event.data);
        mediaRecorder.current.onstop = () => {
          const blob = new Blob(chunks.current, { type: 'audio/wav' });
          setRecordedAudio(blob);
          chunks.current = [];
          setShowRecord(true);
          setShowUpload(true);
        };
        mediaRecorder.current.start();
        setIsRecording(true);
        setIsRecordingPulse(true);
        setIsPaused(false);
      });
      setShowUpload(false);

    }
  };

  const handleStopRecording = () => {
    if (isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsRecordingPulse(false);
      setIsPaused(false);
    }
  };

  const handleReRecord = () => {
    setRecordedAudio(null);
    setAudioFile(null);
  };


  const handleAudioSelect = (selectedAudio) => {
    setSelectedPredefinedAudio(selectedAudio);
    setConvertFile(null);
  };

  return (
    <>
      <section>
        <div ref={bottomRef}
          style={{
            fontSize: "40px",
            display: "flex",
            justifyContent: "center",
            color: "red",
            textAlign: "center",
          }}
        >
          <h3 className="affirmation-heading">
            Create Your Personal Affirmation Magic <br /> here and now:
          </h3>
        </div>
        {recordedAudio || audioFile ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <div style={{ justifyContent: 'space-between', alignItems: 'center', width: '90%' }}>
              <AudioPlayer recordedAudio={recordedAudio} audioFile={audioFile} volume={volumePercentage1} />
            </div>
          </div>

        ) : (
          <div className='voice-actions-container'>
            {showRecord && (
              <div
              className='record'
                style={{
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'red',
                  textAlign: 'center',
                  marginRight: '20px',
                }}
              >
                <h3
                  style={{
                    color: 'white',
                    alignSelf: 'center',
                    marginTop: '40px',
                    marginBottom: '2px',
                    padding: '70px 40px 70px 40px',
                    borderRadius: '95px',
                    cursor: 'pointer',
                    background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                  }}
                  onClick={handleRecording}
                >
                  {!isRecordingPulse ? 'RECORD VOICE' : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="recording-pulse"></span>
                      <span style={{ marginLeft: '10px' }}>Recording...</span>
                    </div>
                  )}
                </h3>
              </div>
            )}     {isRecording && (
              <>
                {isPaused ? (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      opacity: 1,
                      cursor: 'pointer',
                      marginTop: '35px',
                      marginLeft: '35px'
                    }}
                    src={play}
                    alt="Play icon"
                    onClick={handleRecording}
                  />
                ) : (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      opacity: 1,
                      cursor: 'pointer',
                      marginTop: '35px',
                      marginLeft: '35px'
                    }}
                    src={pause}
                    alt="Pause icon"
                    onClick={handleRecording}
                  />
                )}
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    opacity: 1,
                    cursor: 'pointer',
                    marginTop: '35px',
                    marginLeft: '35px'
                  }}
                  src={stop}
                  alt="Stop icon"
                  onClick={handleStopRecording}
                />
              </>
            )}
            {showUpload && (
              <div
                style={{
                  fontSize: '30px',
                  color: '#f492ed',
                  margin: '0 20px',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  marginTop: '25px',
                  fontFamily: 'cursive',

                }}
              >
                OR
              </div>
            )}
            {showUpload && (
              <div
              className='recording'
                style={{
                  fontSize: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'red',
                  textAlign: 'center',
                  marginLeft: '20px',
                }}
              >
                <h3
                  style={{
                    color: 'white',
                    alignSelf: 'center',
                    marginTop: '40px',
                    marginBottom: '2px',
                    padding: '50px 40px 40px 40px',
                    borderRadius: '95px',
                    background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                    cursor: 'pointer',
                  }}
                >
                  <label htmlFor="fileInput">
                    UPLOAD <br />
                    <span >VOICE RECORDING</span>
                  </label>
                  <input type="file" onChange={handleAudioFileChange} accept="audio/*" style={{ display: 'none' }} id="fileInput" />
                </h3>

              </div>
            )}
          </div>
        )}
        {(recordedAudio || audioFile) && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <div style={{ width: '90%', textAlign: 'center' }}>
              <button
                style={{
                  padding: '10px 20px',
                  background: 'linear-gradient(to right, #b5eaef, #ede38f, #f492ed)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={handleReRecord}
              >
                Re-record or Re-upload Audio
              </button>
            </div>
          </div>
        )}
        <div
          style={{
            fontSize: "25px",
            display: "flex",
            justifyContent: "center",
            color: "red",
            textAlign: "center",
          }}
        >
          <h1
            className="pink-color"
            style={{
              marginTop: "40px",
              marginBottom: "2px",
              padding: "10px 20px 10px 20px",
              borderRadius: "40px",
            }}
          >
            Choose your favourite manifestation soundtrack
          </h1>
        </div>

        <PredefinedAudios
          audioOptions={audioOptions}
          onSelect={handleAudioSelect}
          volume={volumePercentage2}
        />
        {(recordedAudio || audioFile) && selectedPredefinedAudio && (
          <>


            <MergePlayAudio
              audioFile1={selectedPredefinedAudio}
              audioFile2={audioFile || recordedAudio}
              volumePercentage1={volumePercentage1}
              volumePercentage2={volumePercentage2}
              // convertFile={convertFile}
              setShowMergeButton={setShowMergeButton}
            />


          </>
        )}
      </section>
    </>
  );
};

export default Application;
