import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="section-heading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ff69b4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-file-text"
        >
          <path d="M4 1v22h16V7.5L14.5 1H4z"></path>
          <polyline points="14 1 14 7 20 7"></polyline>
          <line x1="16" y1="5" x2="8" y2="5"></line>
          <polyline points="10 18 6 18 6 14"></polyline>
          <line x1="9" y1="15" x2="9" y2="21"></line>
        </svg>
        <h1>Privacy Policy of Yourownaffirmations.com</h1>
      </div>

      {/* <p className="last-modified">Last modified on [Date]</p> */}

      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          At Yourownaffirmations.com, we value the privacy of our users. This privacy policy explains how we collect, use, protect, and share personal information when you use our services. By using our website, you agree to the terms of this privacy policy.
        </p>
      </section>

      <section className="policy-section">
        <h2>Information We Collect</h2>
        <div>
          <h3>Personal Information</h3>
          <ul>
            <li>Contact Information: Name, email address, and, if applicable, phone number.</li>
            <li>Recording Information: Audio recordings of your affirmations.</li>
            <li>Payment Information: Payment details are collected and processed through a secure payment gateway and are not stored by us.</li>
          </ul>
        </div>
        <div>
          <h3>Non-Personal Information</h3>
          <ul>
            <li>Technical Data: IP address, browser type, operating system, and website usage information.</li>
          </ul>
        </div>
      </section>

      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <ul>
          <li>Service Delivery: To create and deliver your personalized affirmation audio.</li>
          <li>Customer Support: To respond to inquiries and provide support.</li>
          <li>Payment Processing: To handle payments and fulfill orders.</li>
          <li>Website Improvement: To analyze website usage and improve our services.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2>How We Protect Your Information</h2>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems and are required to keep the information confidential.
        </p>
      </section>

      <section className="policy-section">
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
        </p>
      </section>

      <section className="policy-section">
        <h2>Third-Party Links</h2>
        <p>
          Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.
        </p>
      </section>

      <section className="policy-section">
        <h2>Your Consent</h2>
        <p>By using our site, you consent to our privacy policy.</p>
      </section>

      <section className="policy-section">
        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. When we do, we will post the updated policy on this page and update the Privacy Policy modification date below.
        </p>
        {/* <p className="last-modified">Last modified on [Date].</p> */}
      </section>

      <section className="policy-section">
        <h2>Contacting Us</h2>
        <p>
          If there are any questions regarding this privacy policy, you may contact us using the information below:
        </p>
        <p>
          Yourownaffirmations.com<br />
          Email: yourownaffirmations.com
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
